<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id!=null">Edit</b>
      <b v-else>Create</b>
    </span>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Distribution Channel</label>
        <div class="vx-row">
          <div class="vx-col w-full">
            <multiselect
              v-model="customerCategory.selected"
              :options="customerCategory.options"
              placeholder="Type to search"
              track-by="name"
              label="name"
              :max-height="125"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}</span
                  >
                </div>
              </template>
            </multiselect>
          </div>
        </div>

        <span
          class="text-danger text-sm"
          v-show="errors.has('CustomerCategory')"
          >{{ errors.first("CustomerCategory") }}</span
        >
      </div>
    </div>
    
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Customer Group</label>
        <div class="vx-row">
          <div class="vx-col w-full">
            <multiselect
              v-model="customerGroup.selected"
              :options="customerGroup.options"
              placeholder="Type to search"
              track-by="name"
              label="name"
              :max-height="125"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}</span
                  >
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <span
          class="text-danger text-sm"
          v-show="errors.has('CustomerGroup')"
          >{{ errors.first("CustomerGroup") }}</span
        >
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose">Close</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: Number
    },
    pricingGroupId: {
      type: Number
    },
    url: {
      type: String
    }
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          customerGroupId: null,          
          customerCategoryId: null,
          id: null,
          name: "",
          Code: ""
        },
        customerCategory: {
          selected: null,
          options: [],
        },
        customerGroup: {
          selected: null,
          options: [],
        },
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (!this.data.customerCategoryId) {
          this.errors.add({
            field: "CustomerCategory",
            msg: "The Distribution Channel field is required",
          });
          result = false;
        }
        if (!this.data.customerGroupId) {
          this.errors.add({
            field: "CustomerGroup",
            msg: "The Customer Group field is required",
          });
          result = false;
        }
        if (result) {
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      return {        
        pricing_group_id: this.pricingGroupId,
        customer_group_id: this.data.customerGroupId,
        customer_category_id: this.data.customerCategoryId,
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New Menu Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Menu Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.id = resp.data.id;

            if (resp.data.customer_category_id) {
              this.setCustomerCategorySelected(
                resp.data.customer_category_id
              );
            }

            if (resp.data.customer_group_id) {
              this.setCustomerGroupSelected(resp.data.customer_group_id);
            }

          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getAllDataCustomerCategory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/customer-category", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerCategory.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataCustomerGroup() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/customer-group", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerGroup.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setCustomerCategorySelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/customer-category/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerCategory.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCustomerGroupSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/customer-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerGroup.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    }, 
  },
  mounted() {
    this.getAllDataCustomerCategory();
    this.getAllDataCustomerGroup();
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },    
    "customerCategory.selected": function (val) {
      if (val) {
        this.errors.clear();
        this.data.customerCategoryId = val.id ? val.id : val.ID;
      } else {
        this.data.customerCategoryId = null;
      }
    },
    "customerGroup.selected": function (val) {
      if (val) {
        console.log(val)
        this.errors.clear();
        this.data.customerGroupId = val.id ? val.id : val.ID ;
      } else {
        this.data.customerGroupId = null;
      }
    },
  }
};
</script>
<style >
</style>
